const Routes = {
  ADHD_CARE_JOURNEY: '/adhd-care-journey',
  ADHD_CARE_UPLOAD_MEDICAL_RECORDS: '/adhd-care-journey/upload-medical-records',
  APPOINTMENTS: '/appointments',
  APPOINTMENT_QUESTIONNAIRES: (id: string) =>
    `/appointments/${id}/questionnaires`,
  AVAILABILITIES: '/availabilities',
  BILLING: '/billing',
  CHECKOUT: '/checkout',
  CLINICAL_SUMMARY: (id: string) => `/appointments/${id}/summary`,
  HOME: '/homepage',
  INVOICE: (id: string) => `/invoices/${id}`,
  INVOICES: '/invoices',
  LAB_REPORT: (id: string) => `/lab-reports/${id}`,
  LAB_REPORTS: '/lab-reports',
  LOGIN: '/login',
  PAYMENT: '/payment',
  PHARMACIES: '/pharmacies',
  PRESCRIPTION: (id: string) => `/prescriptions/${id}`,
  PRESCRIPTIONS: '/prescriptions',
  PRODUCT: (id: string) => `/products/${id}`,
  PRODUCTS: '/products',
  PROFILE: '/profile',
  QUESTIONNAIRE_RESPONSE: (id: string) => `/questionnaire-responses/${id}`,
  QUESTIONNAIRE_RESPONSES: '/questionnaire-responses',
  RECORDS: '/records',
  SECURE_CARE_INBOX: '/conversations',
  SIGN_OUT: '/sign-out',
} as const

export default Routes

export function routeBelongsToRecordsPage(route: string) {
  const recordsRoutes = [
    Routes.PRESCRIPTIONS,
    Routes.LAB_REPORTS,
    Routes.QUESTIONNAIRE_RESPONSES,
  ]
  return recordsRoutes.some((recordRoute) => route.includes(recordRoute))
}

export function isBookingFlowRoute(route: string) {
  const bookingFlowRoutes = [
    Routes.PRODUCTS,
    Routes.PAYMENT,
    Routes.AVAILABILITIES,
    Routes.CHECKOUT,
  ]

  return bookingFlowRoutes.some((bookingFlowRoute) =>
    route.includes(bookingFlowRoute),
  )
}

export function routeBelongsToBillingPage(route: string) {
  return route.includes(Routes.INVOICES)
}
